import React from 'react';
import './App.css';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="container">
        <div className="md:flex py-10 lg:py-20 mb-12 lg:mb-24 justify-between border-b-2 dark:border-[rgba(255,255,255,0.1)] items-end">
          <div>
            <div className="mb-4">
              <svg
                className="w-16 h-auto"
                viewBox="0 0 365 420"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M46.87 211.905c1.953 8.207 2.93 18.66 2.93 31.361v134.822c0 9.379-1.954 16.608-5.86 21.689-4.686 5.471-10.643 10.258-17.869 14.361C18.846 418.046 13.768 420 10.84 420 4.003 420 .586 415.799.586 407.397V129.839c0-2.344.586-4.2 1.758-5.568 1.171-1.563 2.636-2.345 4.394-2.345 2.929 0 5.858 2.931 8.788 8.793 3.124 5.862 8.593 18.367 16.404 37.515 8.202 20.517 13.182 35.074 14.94 43.671zm72.063 133.357L2.343 41.912C.782 38.2 0 34.585 0 31.068c0-4.69 1.855-9.086 5.566-13.19 4.491-5.47 10.448-9.77 17.869-12.895C30.856 1.66 36.617 0 40.718 0c4.297 0 7.617.977 9.96 2.93 2.539 1.955 4.883 5.57 7.03 10.845l99.014 266.42c3.515 9.575 5.272 17.39 5.272 23.448 0 4.885-1.171 10.746-3.515 17.585-4.687 11.919-9.276 21.298-13.768 28.137-4.101 6.448-8.495 9.672-13.182 9.672-2.344 0-4.492-.977-6.445-2.931-1.953-1.954-4.003-5.569-6.151-10.844zm156.428-137.167l-61.224 159.149c-6.444 16.022-16.6 28.82-30.465 38.395C170.197 415.213 159.749 420 152.327 420c-5.858 0-8.788-2.736-8.788-8.207 0-2.344.977-5.764 2.93-10.258L266.28 83.825c3.515-7.23 6.347-11.334 8.495-12.31 1.172-.587 2.344-.88 3.516-.88 4.491 0 7.323 3.81 8.495 11.43 1.171 6.84 1.757 22.666 1.757 47.481 0 15.437-.878 29.016-2.636 40.74-1.562 11.528-5.078 24.131-10.546 37.809zM365 12.603v363.726c0 9.575-1.953 16.707-5.859 21.396-4.882 5.862-11.913 11.04-21.091 15.534-8.984 4.494-16.307 6.741-21.971 6.741-6.639 0-9.959-4.201-9.959-12.603V43.671c0-8.207 1.952-14.753 5.858-19.637 5.078-6.058 12.108-11.529 21.092-16.414C342.053 2.54 349.279 0 354.747 0 361.582 0 365 4.201 365 12.603z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <h1 className="mb-1 text-3xl text-[#EF4739] font-semibold">
              Madhusudan Jangid
            </h1>
            <h2 className="text-xl text-black/60 font-semibold">
              Mathematics Faculty
            </h2>
          </div>
          <div className="text-black/80 mt-4 md:mt-0 leading-8">
            <div>
              Email:{' '}
              <a href="mailto:glowingion@gmail.com">glowingion@gmail.com</a>
            </div>
            {/* Phone number to be used in PDF generation only */}
            {process.env.NODE_ENV !== 'production' && (
              <div>
                Phone: <a href="tel:+919167784435">+91-9167784435</a>
              </div>
            )}
            <div>
              Web:{' '}
              <a href="https://madhusudanjangid.com/">
                madhusudanjangid.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="md:grid md:grid-cols-[34fr_55fr] mb-10">
          <h3 className="font-semibold">Bio</h3>
          <div className="mt-2 md:mt-0 pl-8 md:w-full">
            <p>
              Having a keen interest in mathematics, I like to use my dynamic
              pedagogy to educate students. When not teaching, you can find me
              playing billiards or watching movies.
            </p>
          </div>
        </div>
        <div className="md:grid md:grid-cols-[34fr_55fr] mb-10">
          <h3 className="font-semibold">Academic Qualifications</h3>
          <div className="mt-2 md:mt-0 pl-8 md:w-full">
            <ul className="list-disc">
              <li>
                Completed 96 credits towards a <span className="font-semibold">Master's in Mathematics</span> from <span className="font-semibold">IIT Bombay</span>.
              </li>
              <li className="mt-2">
                B.Sc. Mathematics (Hons.) from University Maharaja's College,
                Jaipur in 2014 with 67%.
              </li>
              <li className="mt-2">
                HSC from Rajasthan Board in 2010 with 70%.
              </li>
              <li className="mt-2">
                SSC from CBSE in 2008 with 72%.
              </li>
            </ul>
          </div>
        </div>
        <div className="md:grid md:grid-cols-[34fr_55fr] mb-10">
          <h3 className="font-semibold">Scholastic Achievements</h3>
          <div className="mt-2 md:mt-0 pl-8 md:w-full">
            <ul className="list-disc">
              <li>Secured AIR-97 in IIT JAM 2014.</li>
              <li className="mt-2">
                Secured 27th position in Jaipur district in Pratibha Khoj Pariksha 2010.
              </li>
              <li className="mt-2">
                Cleared NBHM M.A./M.Sc. scholarship written test conducted by TIFR Mumbai in 2014.
              </li>
              <li className="mt-2">
                Recipient of M.Sc. Merit-cum-Means scholarship in 2014.
              </li>
            </ul>
          </div>
        </div>
        <div className="md:grid md:grid-cols-[34fr_55fr] mb-10">
          <h3 className="font-semibold">Experience</h3>
          <div className="mt-2 md:mt-0 pl-8 md:w-full">
            <ul className="list-disc">
              <li className="mt-2">
                Worked as <span className="font-semibold">Mathematics faculty for JEE</span> at <span className="font-semibold">Allen Madhya Pradesh</span> from November 2022 to May 2024.
              </li>
              <li className="mt-2">
                Worked as <span className="font-semibold">Mathematics faculty for JEE</span> at <span className="font-semibold">Aakash Byju's Bhilwara</span> from February to September 2022.
              </li>
              <li className="mt-2">
                Worked as <span className="font-semibold">Mathematics Content Developer</span> for <span className="font-semibold">Edification</span>, a digital platform for JEE and school academics, 2021-2022.
              </li>
              <li className="mt-2">
                Worked as <span className="font-semibold">faculty for JEE Advanced and Mains</span> at <span className="font-semibold">Unique Tutorials</span>, Mumbai, 2019-2021.
              </li>
              <li className="mt-2">
                Worked as <span className="font-semibold">faculty</span> for <span className="font-semibold">Vyaas Education Hub</span>, Jaipur, Rajasthan, 2018-2019.
              </li>
              <li className="mt-2">
                Selected for a <span className="font-semibold">faculty position</span> at <span className="font-semibold">FIITJEE, Delhi</span> in 2017.
              </li>
              <li className="mt-2">
                Working as <span className="font-semibold">Q&A Expert</span> for <span className="font-semibold">Chegg</span> since 2017.
              </li>
            </ul>
          </div>
        </div>
        <div className="md:grid md:grid-cols-[34fr_55fr] mb-10">
          <h3 className="font-semibold">Extra Curricular</h3>
          <div className="mt-2 md:mt-0 pl-8 md:w-full">
            <ul className="list-disc">
              <li>
                Team member in the 35th Mathematics Olympiad organized by IIT
                Bombay in 2016.
              </li>
              <li className="mt-2">
                B Certificate holder in NCC in 2013.
              </li>
              <li className="mt-2">
                NCC cadet in school and college; participated in camps in 2006
                and 2012.
              </li>
              <li className="mt-2">
                Participated in dance during Republic Day and Annual Day in
                school.
              </li>
            </ul>
          </div>
        </div>
        <div className="md:grid md:grid-cols-[34fr_55fr] mb-10">
          <h3 className="font-semibold">Computer Skills &amp; Hobbies</h3>
          <div className="mt-2 md:mt-0 pl-8 md:w-full">
            <ul className="list-disc">
              <li>C programming language, MATLAB, LaTeX.</li>
              <li className="mt-2">
                Painting, photography, billiards, badminton, basketball.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-auto print:hidden">
        <div className="container">
          <div className="md:flex py-10 lg:py-20 mt-12 lg:mt-24 justify-between border-t-2 dark:border-[rgba(255,255,255,0.1)]">
            <div className="opacity-60">
              Copyright © 2021 madhusudanjangid.com
            </div>
            <div className="opacity-60 mt-4 md:mt-0 leading-8">
              <div>Made with ❤️ by a dear friend.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
